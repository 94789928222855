import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {withRouter} from 'react-router'
import {Link} from "react-router-dom";
import './../css/footer.scss';

class Footer extends Component {
  render() {
    const {
      t, i18n
    } = this.props;
    return (
      <footer>
        <div className={'center'}>
          <div className={'max-width width footer-flex'}>
            <div className={'row-menu'} style={{flex: 1}}>
              <div className={'title'}>{t('menu.products')}</div>
              <Link to={"/" + i18n.language + "/dedicated"}>{t('menu.dedicated')}</Link>
              <Link to={"/" + i18n.language + "/buy_server_with_bitcoin"}>{t('menu.dedicated-crypto')}</Link>
              <Link to={"/" + i18n.language + "/vps"}>{t('menu.vps')}</Link>
              <Link to={"/" + i18n.language + "/cloud"}>{t('menu.cloud')}</Link>
              <Link to={"/" + i18n.language + "/ddos"}>{t('menu.ddos')}</Link>
              <Link to={"/" + i18n.language + "/cdn"}>{t('menu.cdn')}</Link>
              <Link to={"/" + i18n.language + "/colocation"}>{t('menu.colocation')}</Link>
              <Link to={"/" + i18n.language + "/storage"}>{t('menu.storage')}</Link>
              <Link to={"/" + i18n.language + "/apps"}>{t('menu.apps')}</Link>
            </div>
            <div className={'row-menu'} style={{flex: 1}}>
              <div className={'title'}>{t('menu.technology')}</div>
              <Link to={"/" + i18n.language + "/hardware"}>{t('menu.hardware')}</Link>
              <Link
                to={"/" + i18n.language + "/network_connectivity"}>{t('menu.network_connectivity')}</Link>
              <Link to={"/" + i18n.language + "/future_ready"}>{t('menu.future_ready')}</Link>
              <Link to={"/" + i18n.language + "/network_status"}>{t('menu.network_status')}</Link>
              <Link to={"/" + i18n.language + "/faq"}>{t('menu.faq')}</Link>
            </div>
            <div className={'row-menu'} style={{flex: 1}}>
              <div className={'title'}>{t('menu.company')}</div>
              <Link to={"/" + i18n.language + "/about"}>{t('menu.about')}</Link>
              <Link to={"/" + i18n.language + "/location"}>{t('menu.location')}</Link>
              <Link to={"/" + i18n.language + "/press"}>{t('menu.press')}</Link>
              <Link
                to={"/" + i18n.language + "/business_safety_guarantee"}>{t('menu.business_safety_guarantee')}</Link>
              <Link to={"/" + i18n.language + "/contact"}>{t('menu.contact')}</Link>
              <Link to={"/" + i18n.language + "/partners"}>{t('menu.partners')}</Link>
              <Link to={"/" + i18n.language + "/reseller"}>{t('menu.reseller')}</Link>

              {/*<Link to={"/"+i18n.language+"/terms_of_service"}>{t('menu.terms_of_service')}</Link>*/}
              {/*<Link to={"/"+i18n.language+"/acceptable_use_policy"}>{t('menu.acceptable_use_policy')}</Link>*/}
            </div>
            <div className={'row-menu'} style={{flex: 1.2}}>
              <div className={'title'}>{t('menu.address_and_contact')}</div>
              <div className={'bold'}>{t('menu.dc_address')}</div>
              <div className={'text'}>Sägereistrasse 35, CH-8152 Glattbrugg, Switzerland</div>
              <div className={'space'}/>
              <div className={'bold'}>{t('menu.company_address')}</div>
              <div className={'text'}>Murtschenstrasse 30, CH-8048 Zurich, Switzerland</div>

              {/*<iframe*/}
              {/*  title={"myFrame"}*/}
              {/*  style={{border:0}}*/}
              {/*  src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2701.239600222783!2d8.494143515627883!3d47.38775777917073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900bcae67ca46b%3A0xb714e3914883f663!2sSolar%20Communications%20GmbH!5e0!3m2!1sru!2sch!4v1674854907912!5m2!1sru!2sch"}*/}
              {/*  width={"480"} height={"240"} allowFullScreen={""} loading={"lazy"}*/}
              {/*  referrerPolicy={"no-referrer-when-downgrade"}/>*/}

              <div className={'social-container'}>
                <a href={'https://facebook.com/SolarCommunications'}>
                  <div className={'social-block facebook'}/>
                </a>
                <a href={'https://twitter.com/SolarComSwiss'}>
                  <div className={'social-block twitter'}/>
                </a>
                <a href={'https://www.linkedin.com/company/solarcomswiss/'}>
                  <div className={'social-block linkedin'}/>
                </a>

              </div>

            </div>
          </div>
        </div>
        <div className={'center border-top'}>
          <div className={'max-width width'}>
            <div className={'bottom footer-flex'}>
              <div className={'currency-block'}>
                <div className={'icon visa'}></div>
                <div className={'icon master'}></div>
                <div className={'icon sepa'}></div>
                <div className={'icon swift'}></div>
                <div className={'icon paypal'}></div>
                <div className={'icon bitcoin'}></div>
                <div className={'icon crypto'}></div>
              </div>
              <div className={'links'}>
              <Link to={"/" + i18n.language + "/privacy_policy"}>{t('menu.privacy_policy')}</Link>
                {this.props.mobile && <br/>}
                <Link to={"/" + i18n.language + "/terms_of_service"}>{t('menu.terms_of_service')}</Link>
                {this.props.mobile && <br/>}
                <font>Copyright @ 2011 - 2023 (v.2.3)</font>
              </div>
            </div>
          </div>
        </div>

      </footer>
    );

  }
}

export default withRouter(translate('header')(Footer));

